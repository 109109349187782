/* You can add global styles to this file, and also import other style files */

$Navy         : white;  //0a192f
$LightNavy    : #ececec; //112240
$LightestNavy : #303C55; //303C55
$Slate        : #303C55; //8892b0
$LightSlate   : #0f060f; //a8b2d1
$LightestSlate: #303C55; //ccd6f6
$White        : #303C55; //e6f1ff
$Green        : #0a192f; //64ffda
$GreenOpacity : #0a192f; //rgba(100,255,218,0.07)

$MainFont: Roboto, Calibre,San Francisco,SF Pro Text,-apple-system,system-ui,BlinkMacSystemFont,Roboto,Helvetica Neue,Segoe UI,Arial,sans-serif;
$CodeFont: Roboto, Calibre, SF Mono, Fira Code, Fira Mono, Roboto Mono, Lucida Console, Monaco, Monospace;
